import { Plan } from '@wix/ambassador-checkout-server/types';
import _ from 'lodash';
import {
  PricingPlan,
  ReservedLocationIds,
  Service,
  StaffMember,
} from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { BottomSectionStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { EmptyStateType } from '../../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { SlotsStatus } from '../../types/types';
import { getValidPurchasedPricingPlansForService } from '../pricingPlans/pricingPlans';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking } from '@wix/bookings-checkout-api';

export function createInitialState({
  services,
  wixSdkAdapter,
  staffMembers,
  rescheduleBookingDetails,
  initialErrors,
  isAnonymousCancellationFlow,
  allPurchasedPricingPlans,
  isShowPricingPlanEndDateIndicationEnabled,
  isPricingPlanInstalled,
  isUserLoggedIn,
  serviceVariants,
  isAnywherePublicDataOverrides,
}: {
  services: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  staffMembers?: StaffMember[];
  rescheduleBookingDetails?: Booking;
  initialErrors: EmptyStateType[];
  isAnonymousCancellationFlow?: boolean;
  allPurchasedPricingPlans?: Balance[];
  isShowPricingPlanEndDateIndicationEnabled: boolean;
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
  serviceVariants?: ServiceOptionsAndVariants;
  isAnywherePublicDataOverrides?: boolean;
}): CalendarState {
  const locationFilterOptions = initializeLocationFilterOptions(wixSdkAdapter);
  const staffFilterOptions = initializeStaffMembersFilterOptions({
    wixSdkAdapter,
    staffMembers,
    services,
  });
  const purchasedPricingPlans = initializePurchasedPricingPlans({
    services,
    allPurchasedPricingPlans,
    isShowPricingPlanEndDateIndicationEnabled,
    isPricingPlanInstalled,
    isUserLoggedIn,
  });
  const selectedDate = initializeSelectedDate(isAnonymousCancellationFlow);

  const hasReferralParam = initializeHasReferralParam(
    wixSdkAdapter,
    isAnywherePublicDataOverrides,
  );

  return {
    bottomSectionStatus: BottomSectionStatus.LOADING,
    slotsStatus: SlotsStatus.LOADING,
    availableServices: services || [],
    selectedDate,
    selectedTimezone: undefined,
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlotsAtSelectedTime: undefined,
    selectedRange: undefined,
    availableSlotsPerDay: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    rescheduleBookingDetails,
    dialog: undefined,
    filterOptions: {
      [FilterTypes.SERVICE]: [],
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
    },
    initialErrors,
    purchasedPricingPlans,
    isUserLoggedIn,
    selectedVariantsOptions: [],
    serviceVariants,
    hasReferralParam,
  };
}

const initializeLocationFilterOptions = (
  wixSdkAdapter: WixOOISDKAdapter,
): string[] => {
  let locationFilterOptions: string[] = [];

  const locationQueryParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.LOCATION,
  );

  if (locationQueryParam) {
    if (Array.isArray(locationQueryParam)) {
      locationFilterOptions = locationQueryParam;
    } else {
      locationFilterOptions.push(locationQueryParam);
    }

    return locationFilterOptions.filter(
      (location) => location !== ReservedLocationIds.OTHER_LOCATIONS,
    );
  }

  return locationFilterOptions;
};

const initializeStaffMembersFilterOptions = ({
  services,
  staffMembers,
}: {
  services: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  staffMembers?: StaffMember[];
}): string[] => {
  const staffFilterOptions: string[] = [];

  if (staffMembers) {
    const staffMemberId = staffMembers?.[0]?.id;

    if (staffMemberId) {
      const isStaffMemberConnectedToService = services.some((service) =>
        _.find(service?.staffMembers, ({ id }) => id === staffMemberId),
      );
      if (isStaffMemberConnectedToService) {
        staffFilterOptions.push(staffMemberId);
      }
    }
  }

  return staffFilterOptions;
};

const initializePurchasedPricingPlans = ({
  services,
  allPurchasedPricingPlans,
  isShowPricingPlanEndDateIndicationEnabled,
  isPricingPlanInstalled,
  isUserLoggedIn,
}: {
  services: Service[];
  allPurchasedPricingPlans?: Balance[];
  isShowPricingPlanEndDateIndicationEnabled: boolean;
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
}): Plan[] => {
  let purchasedPricingPlans: Plan[] = [];
  let servicePricingPlans: PricingPlan[] = [];

  if (isShowPricingPlanEndDateIndicationEnabled && isPricingPlanInstalled) {
    if (isUserLoggedIn) {
      servicePricingPlans =
        (services.length &&
          services[0].payment.pricingPlanInfo?.pricingPlans) ||
        [];
      purchasedPricingPlans = getValidPurchasedPricingPlansForService({
        servicePricingPlans,
        allPurchasedPricingPlans: allPurchasedPricingPlans || [],
      });
    }
  }
  return purchasedPricingPlans;
};

const initializeSelectedDate = (isAnonymousCancellationFlow?: boolean) => {
  let selectedDate;

  if (isAnonymousCancellationFlow) {
    // @ts-expect-error
    const dateQueryParam = wixSdkAdapter.getUrlQueryParamValue('date');
    if (dateQueryParam) {
      if (Array.isArray(dateQueryParam)) {
        selectedDate = dateQueryParam[0];
      } else {
        selectedDate = dateQueryParam;
      }
    }
  }

  return selectedDate;
};

const initializeHasReferralParam = (
  wixSdkAdapter: WixOOISDKAdapter,
  isAnywherePublicDataOverrides?: boolean,
) => {
  return isAnywherePublicDataOverrides
    ? !!wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL)
    : undefined;
};

import { AddError } from '../addError/addError';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { isSlotWithOpenWaitingList } from '../../../../utils/slotAvailability/slotAvailability';
import { checkForPricingPlanError } from '../../../../utils/pricingPlans/pricingPlans';
import { OnServiceVariantsOptionSelected } from '../onServiceVariantsOptionSelected/onServiceVariantsOptionSelected';
import { isServiceVariantWithStaff } from '../../../../utils/dynamicPricing/dynamicPricing';

export type SetSelectedTime = (selectedTime: string | undefined) => void;

export function createSetSelectedTimeAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  onServiceVariantsOptionSelected: OnServiceVariantsOptionSelected,
): SetSelectedTime {
  return (selectedTime: string | undefined) => {
    const [state, setState] = getControllerState();
    const {
      availableSlots,
      purchasedPricingPlans,
      rescheduleBookingDetails,
      serviceVariants,
    } = state;
    const { experiments } = context;
    setState({
      calendarErrors: [],
      selectedVariantsOptions: [],
    });
    let selectableSlots;
    if (selectedTime) {
      selectableSlots = availableSlots?.availabilityEntries?.filter(
        (availableSlot) => {
          return (
            availableSlot.slot?.startDate === selectedTime &&
            (availableSlot.bookable || isSlotWithOpenWaitingList(availableSlot))
          );
        },
      );

      const isShowPricingPlanEndDateIndicationEnabled = experiments.enabled(
        'specs.bookings.ShowPricingPlanEndDateIndication',
      );
      const isDynamicPricingUoUEnabled = experiments.enabled(
        'specs.bookings.dynamicPricingUoU',
      );

      if (isShowPricingPlanEndDateIndicationEnabled) {
        const isRescheduleFlow = !!rescheduleBookingDetails;
        const pricingPlanError = checkForPricingPlanError({
          purchasedPricingPlans,
          isRescheduleFlow,
          selectedTime,
        });
        if (pricingPlanError) {
          addError(pricingPlanError);
        }
      }

      if (
        isDynamicPricingUoUEnabled &&
        isServiceVariantWithStaff(serviceVariants) &&
        selectableSlots &&
        selectableSlots.length > 0
      ) {
        const firstSlotStaffId = selectableSlots[0].slot!.resource!.id!;
        if (
          selectableSlots.every(
            ({ slot }) => firstSlotStaffId === slot!.resource!.id,
          )
        ) {
          onServiceVariantsOptionSelected({
            optionId: serviceVariants?.options![0].id!,
            choiceId: firstSlotStaffId,
            numberOfParticipants: 1,
            isMultipleChoices: false,
          });
        }
      }
    }

    setState({
      selectedBookingPreferences: [],
      selectableSlotsAtSelectedTime: selectableSlots ?? [],
      selectedTime,
    });
  };
}
